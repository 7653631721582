import "bundles/core";
import "shared/sentry.io-video";
import "bundles/video/lockSlateTracking";
import 'core/bundles/livetv/FreeWheelGDPRHandler';
import {setAuthServiceFactory} from "components/liveTVPlayer";
import AdobeAuthorizationService from "mvpd/AdobeAuthorizationService";

setAuthServiceFactory(() => new AdobeAuthorizationService({
    isLiveTV: true,
}))

window.onkeydown = function(e) {
    return !(e.keyCode == 32 && e.target == document.body);
};

import 'bundles/livetv/liveTVSPAHistoryHandler';
